.tabs-list{
    list-style: none;
    display: inline-flex;
    width: 100vw;
    padding: 10px 10px 0px;
    border-bottom: 1px solid black;
    font-family: system-ui;
    
}
.tabs-list li{
    padding: 0px 10px 0px 10px;
    
}
.ant-table-wrapper {
    width: 100%;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: black;
    text-shadow: 0 0 0.25px currentColor;
}
  .dashboard-wrapper {
    margin-top: 5vh;
    padding: 0px 8px 0px 8px;
}
.ant-tabs {
    width: -webkit-fill-available;
}
.ant-table{
    line-height: 1.5;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    
    padding-left: 30px;
 
}
:where(.css-dev-only-do-not-override-15rg2km).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-15rg2km).ant-table-wrapper .ant-table-thead >tr>td {
    color: white;
    background-color: grey;
}

.ant-space-align-center {   
    margin: 0 auto !important;
}

.ant-layout .ant-layout-sider {
    background: #fff;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-btn.ant-btn-round {
    border-radius: 24px;
    padding-inline-start: 0.5rem;
    padding-inline-end: 0.5rem;
    font-size: 0.75rem;
}

.custom-table {
    border: 1px solid #e8e8e8;
  }
  
  .custom-table .ant-table-thead > tr > th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .custom-table .ant-table-cell {
    padding: 8px;
    text-align: center;
  }

  .ant-layout-header{
    height: auto;
  }

  .ant-layout-sider {
    min-width: auto !important;
    max-width: 200px !important;
    width: 200px !important;
    width: auto !important;
  }